.no-button {
    all: unset;
    cursor: pointer;
}

p {
    margin-bottom: 0;
}

#root {
    height: 100vh;
    min-height: 700px;
}

.nav {
    /* min-width: 4rem; */
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    padding: 1.5rem 0.7rem;
    color: white;
    position: sticky;
    top: 0;
    height: 100vh;
}

.nav a {
    color: white;
    text-decoration: none;
}

.nav>h1 {
    writing-mode: vertical-lr;
    margin: 0;
}

.row-upper {
    height: 40%;
}

.bottom-left-lower {
    height: 50%;
}

.bottom-left-upper {
    height: 50%;

}

.row-lower {
    height: 60%;
}

.col-md-3 {
    padding-right: 0;
}

.container-fluid {
    padding-bottom: 0.75rem;

}

.card-container {
    padding-top: 0.75rem;
}

#player-img {
    background-color: rgba(216, 216, 216, 0.557);
}

.finished-task {
    background-color: rgb(237, 240, 237);
}

small {
    font-size: x-small;
}

#add-task-button {
    font-size: x-large;
    position: absolute;
    bottom: 0;
    right: 0;
    margin: 1rem;
    border-radius: 100px;
    padding: 0rem;
    line-height: 0rem;
    height: 2.5rem;
    width: 2.5rem
}

.task-ul {
    margin-left: -2rem;
}

.spinner-loading {
    transition: all 0.3s;
}


/* ===== mobile ===== */
@media only screen and (max-width: 768px) {
    .col-md-3 {
        padding-right: 0.75rem;
    }

    #root {
        height: fit-content;
    }

    .card-container {
        /* height: max-content; */
        min-height: 300px;
    }

    .row-lower,
    .row-upper {
        height: fit-content;
    }

}